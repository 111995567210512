import Vue from "vue"
import Router from "vue-router"
import OktaVue, { LoginCallback } from '@okta/okta-vue'
import { OktaAuth } from '@okta/okta-auth-js'

Vue.use(Router)

const oktaAuth = new OktaAuth({
    issuer: "https://" + process.env.VUE_APP_OKTA_DOMAIN + "/oauth2/default",
    clientId: process.env.VUE_APP_OKTA_CLIENTID,
    redirectUri: window.location.origin + "/login/callback",
    scopes: ["openid", "profile", "email"]
})
Vue.use(OktaVue, { oktaAuth })

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/login/callback",
            component: LoginCallback
        },
        {
            path: "/login",
            component: () => import(`@/views/Login.vue`)
        },
        {
            path: '*',
            meta: {
              name: '',
              requiresAuth: false
            },
            redirect: {
              path: '/'
            }
          },
        {
            path: "/",
            component: () => import("@/views/dashboard/Index"),
            children: [
                // Dashboard
                {
                    meta: {
                        name: "Dashboard",
                        requiresAuth: true
                    },
                    path: "",
                    component: () => import("@/views/dashboard/Dashboard")
                },
                
                // Pages
                {
                    meta: {
                        name: "Delivery Map",
                        requiresAuth: true
                    },
                    name: 'dmap',
                    path: "pages/dmap",
                    component: () =>
                        import("@/views/dashboard/pages/DeliveryMap")
                },
                {
                    meta: {
                        name: "Notifications",
                        requiresAuth: true
                    },
                    path: "components/notifications",
                    component: () =>
                        import("@/views/dashboard/component/Notifications")
                },
                {
                    meta: {
                        name: "Icons",
                        requiresAuth: true
                    },
                    path: "components/icons",
                    component: () => import("@/views/dashboard/component/Icons")
                },
                {
                    name: "Typography",
                    path: "components/typography",
                    component: () =>
                        import("@/views/dashboard/component/Typography")
                },
                // Tables
                {
                    meta: {
                        name: "Regular tables",
                        requiresAuth: true
                    },
                    path: "tables/regular-tables",
                    component: () =>
                        import("@/views/dashboard/tables/RegularTables")
                },
                // Maps
                {
                    meta: {
                        name: "Google maps",
                        requiresAuth: true
                    },
                    path: "/pages/tracking",
                    component: () => import("@/views/dashboard/pages/Tracking")
                }, {
                    meta: {
                        name: "Telemetry",
                        requiresAuth: true
                    },
                    path: "/pages/telemetry",
                    component: () => import("@/views/dashboard/pages/Telemetry")
                }, {
                    meta: {
                        name: "Flight Logs",
                        requiresAuth: true
                    },
                    path: "/pages/flightlogs",
                    component: () => import("@/views/dashboard/pages/FlightLogs")
                },{
                    meta: {
                        name: "Flight Logs",
                        requiresAuth: true
                    },
                    path: "/pages/flightlogs/:flightId",
                    component: () => import("@/views/dashboard/pages/FlightLogs")
                }, {
                    meta: {
                        name: "Reports",
                        requiresAuth: true
                    },
                    path: "/pages/reports",
                    component: () => import("@/views/dashboard/pages/Reports")
                }, {
                    meta: {
                        name: "View Full Order",
                        requiresAuth: true
                    },
                    path: "/pages/vieworder/:de_order_id",
                    component: () => import("@/views/dashboard/pages/FlightLogs")
                }


            ]
        }
    ]
})


const onAuthRequired = async (from, to, next) => {
    if (await Vue.prototype.$auth.isAuthenticated()) {
        next()
    } else if (
        from.matched.some(record => record.meta.requiresAuth) &&
        !(await Vue.prototype.$auth.isAuthenticated())
    ) {
        // Navigate to custom login page
        next({ path: "/login" })
    } else {
        next()
    }
}

router.beforeEach(onAuthRequired)
export default router
