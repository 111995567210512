<template>
    <router-view @logout="logout()"/>
</template>

<script>
  export default {
    name: 'App',
  data: function () {
    return { authenticated: false }
  },
  watch: {
    // Everytime the route changes, check for auth status
    $route: 'isAuthenticated'
  },
  async mounted() {
       console.log('APIURL: ',window.apiurl)
       this.$notification.requestPermission()
       this.fetchSettings().then(res => {
         console.log("settings  from 'local' api call ", res)
         this.appSettings = res;
         // this.settings = true
       })
  },
  created () {
    this.$on('logout', () => { console.log('logout') })
    this.isAuthenticated()
    this.$auth.authStateManager.subscribe(this.isAuthenticated)
  },
  methods: {
    async fetchSettings() {
      const response = await fetch('/api/settings');
      return await response.json();
    },
    async isAuthenticated () {
      this.authenticated = await this.$auth.isAuthenticated()
    },
    async logout () {
      console.log('logging out')
      await this.$auth.signOut()
      await this.isAuthenticated()
    }
  }
}

</script>
