// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import axios from 'axios'
import x5GMaps from 'x5-gmaps'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import { VDigitalTimePicker } from 'v-digital-time-picker'
import JsonCSV from 'vue-json-csv'
import VueNativeNotification from 'vue-native-notification'


Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.component('downloadCsv', JsonCSV)

// Set API & WS URLS for stage vs production (stage azure host has purple in the name...)
if (window.location.origin.indexOf('purple')!==-1 || window.location.origin.indexOf('localhost')!==-1) {
  window.apiurl = process.env.VUE_APP_API_BASE_URL.replace('dls-backend','dls-stage-backend')
  window.apiws = process.env.VUE_APP_API_BASE_WS.replace('dls-backend','dls-stage-backend')
} else {
  window.apiurl = process.env.VUE_APP_API_BASE_URL
  window.apiws = process.env.VUE_APP_API_BASE_WS
}

Vue.config.productionTip = false
axios.defaults.headers.get['Accept'] = 'application/json'
Vue.component('v-digital-time-picker', VDigitalTimePicker)

console.log('url: ',Vue.prototype.$apiurl)
Vue.use(x5GMaps, { key: 'AIzaSyCFqhpkRrD0flBSURAm6LIE-2n7mCWjGVc', libraries: ['visualization', 'places'] })
Vue.use(VueNativeNotification, {requestOnNotify: true})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
