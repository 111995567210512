import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    tableList: [],
    regionId: localStorage.regionId,
    regionInfo: {geometry:{lat:0.0,lng:0.0,radius:0.0}},
    orders: [],
    fullorders: [],
    dxrConfig: {},
    regionList: [],
    showOlderFlightLogs: false,
    accessToken: '',
    deliveryStatusOptions: [],  
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: '',
    telemetry: {},
    drawer: null,
    ws: null, 
    current_orders: {},
    sendNotification: ''
  },
  mutations: {
    setTableList (state, tableList) {
      state.tableList = tableList
    },
    setOlderFlightLogs(state, older) {
      state.showOlderFlightLogs = older
    },
    setSendNotification(state, text) {
      state.sendNotification = text
    },
    setCurrentRegion (state, regionId) {
      if (this.state.accessToken===undefined) {
        return
      }
      console.log("setting current region")
      state.regionId = regionId
      this.dispatch('regionDataUpdater')
      if (this.ws != null) {
        this.ws.close()
      }
      this.ws = new WebSocket(window.apiws+"consumer/mavtelemetry."+state.regionId+".*?token="+state.accessToken)
      this.ws.onmessage = (event) => {
        let data = JSON.parse(event.data);
        if (data===undefined) {
          return
        }
        // console.log("telemetry from: "+data['drone_id'])
        this.dispatch('updateKafkaStream', data)
      }
    },
    setTelemetryData (state, data) {
      // let newdata = state.telemetry
      // let drone_id = data['drone_id']
      let newdata = { ...state.telemetry }
      newdata[data['drone_id']] = data
      state.telemetry = newdata
      // console.log(state.telemetry)
    },
    setOrders (state, orderlist) {
      state.orders = orderlist
    },
    setFullOrders (state, fullorderlist) {
      state.fullorders = fullorderlist
    },
    setRegionList (state, regionList) {
      state.regionList = regionList
    },
    setRegionInfo (state, regionInfo) {
      state.regionInfo = regionInfo
    },
    setDxrConfig (state, config) {
      state.dxrConfig = config
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken
    },
    setDeliveryStatusOptions(state, options) {
      state.deliveryStatusOptions = options
    },
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
  },
  getters: {
    regionId: state => state.regionId,
    regionInfo: state => state.regionInfo,
    regionList: state => state.regionList,
    accessToken: state => state.accessToken,
    deliveryStatusOptions: state => state.deliveryStatusOptions,
    telemetry: state => state.telemetry,
    dxrConfig: state => state.dxrConfig,
    orders: state => state.orders,
    fullorders: state => state.fullorders,
    showOlderFlightLogs: state => state.showOlderFlightLogs,
    sendNotification: state => state.sendNotification
  },
  actions: {
    changeActiveRegion({ commit }) {
    },
    updateKafkaStream({ commit }, data) {
      if (data["drone_id"]!==undefined) {
        commit('setTelemetryData',data)
      }
    },
    loadRegions({ commit }) {
      if (this.state.accessToken===undefined) {
        return
      }
      console.log("APIURL-storejs ", window.apiurl)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.state.accessToken
      axios
        .get(window.apiurl + 'regions')
        .then((response) => {
          let regionList = response.data.regions
          commit('setRegionList', regionList)
          commit('setCurrentRegion', this.state.regionId)
          axios
            .get(
              window.apiurl+'status/' +
                this.state.regionId,
            )
            .then((response) => {
              commit('setRegionInfo', response.data)
            })
        })
        .catch((error) => console.log(error))
      axios.get(window.apiurl+'delivery_status_options')
        .then((response) => {
          commit('setDeliveryStatusOptions', response.data.options)
        })
    },
    saveRegionInfo({ commit }, regionInfo) {
      if (this.state.accessToken===undefined) {
        return
      }

      this.state.regionInfo.available =
        this.state.regionInfo.description === 'Delivery Available'
      axios.post(
        window.apiurl+'status/' +
          this.state.regionInfo.region_id,
        this.state.regionInfo,
      )
    },
    orderUpdater({ commit }) {
      axios
      .get(window.apiurl+'orders/' + this.state.regionId, {params:{older:this.state.showOlderFlightLogs}})
      .then((response) => {
        commit('setOrders', response.data['orders'])
        commit('setFullOrders', response.data['fullorders'])

        let store_order_number = ''
        for (let i=0; i<response.data['fullorders'].length; i++) {
          let order = response.data['fullorders'][i]
          if (order.active) {
            if (!(order.de_order_id in this.state.current_orders)) {
              this.state.current_orders[order.de_order_id] = order.store_order_number
              if (order.store_order_number !== '') {
                store_order_number = order.store_order_number
              }
            }
            if (this.state.current_orders[order.de_order_id] !== order.store_order_number) {
              this.state.current_orders[order.de_order_id] = order.store_order_number
              store_order_number = order.store_order_number
            }
          }
        }
        if (store_order_number!=='') {
          console.log('Sending Notification')
          commit('setSendNotification', 'Order Ready: '+store_order_number)
          // this.$notification.show('Drone Express Notification', {
          //  body: 'Order Ready: '+store_order_number,
          //  silent: false
          // }, {})
        }
      })
    },
    regionDataUpdater({ commit }) {
      if (this.state.accessToken===undefined) {
        return
      }
      axios
        .get(window.apiurl+'status/' + this.state.regionId)
        .then((response) => {
          commit('setRegionInfo', response.data)
        })
        axios
        .get(window.apiurl+'dxrconfig/' + this.state.regionId)
        .then((response) => {
          commit('setDxrConfig', response.data)
        })
    }
  },
})
